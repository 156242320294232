import { useAppSelector } from "@/hooks";
import { IntelliProveService } from "@/utils/intelliprove.service";
import { BucketResult, BucketsResponse } from "intelliprove-streaming-sdk";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { iTrendsPageItem } from "./Trends";

const LABEL_SUFFIX = "_history";

export interface iTrendItem {
  name: string;
  epoch: number;
  value: number | null;
}

export interface iTrendItemCollection {
  [biomarker: string]: Array<iTrendItem>;
}

export const useTrends = (uuid?: string, buckets_response?: BucketsResponse) => {
  const navigate = useNavigate();
  if (uuid === undefined) {
    // TODO: show error or navigate
    navigate("/");
  }

  const [buckets, setBuckets] = useState<BucketsResponse | null>(null);
  const [data, setData] = useState<Array<iTrendsPageItem>>([]);
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);

  const formatEpochToDateString = (epoch: number, short: boolean = true) => {
    const date = new Date(epoch);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1

    if (short) {
      // Format the day and month with leading zeros if needed
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      return `${formattedDay}/${formattedMonth}`;
    } else {
      const formattedDate = `${date.toLocaleString("default", { month: "short" })} ${date.getDate()} - ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
      return formattedDate;
    }
  };

  const sortEpoch = (a: iTrendItem, b: iTrendItem) => {
    return a.epoch - b.epoch;
  };

  const sortByEpoch = (obj: iTrendItemCollection) => {
    for (const key in obj) {
      obj[key].sort(sortEpoch);
      obj[key] = obj[key].filter((item, index, arr) => index === 0 || item.epoch !== arr[index - 1].epoch);

      const allNull = obj[key].every((item) => item.value === null);
      if (allNull) {
        delete obj[key];
      }
    }
  };

  const __trendsCollectionToTrendsPages = (collection: iTrendItemCollection): Array<iTrendsPageItem> => {
    return Object.entries(collection).map(([name, items]) => {
      return {
        title: name + LABEL_SUFFIX,
        values: items.map((i) => {
          return {
            value: i.value,
            label: formatEpochToDateString(i.epoch),
            hint: formatEpochToDateString(i.epoch, false),
          };
        }),
      };
    });
  };

  const __bucketsToTrendItems = (buckets: Array<BucketResult>) => {
    let collection: iTrendItemCollection = {};

    buckets.forEach((buck) => {
      if (buck.history.length === 0) return;
      buck.history.forEach((h) => {
        Object.entries(h.biomarkers).forEach(([name, value]) => {
          if (!Object.keys(collection).includes(name)) {
            collection[name] = [];
          }

          collection[name].push({
            value: value as number,
            epoch: h.timestamp_epoch * 1000,
            name: name,
          });
        });
      });
    });

    sortByEpoch(collection);
    setData(__trendsCollectionToTrendsPages(collection));
  };

  const __fetchBuckets = async () => {
    if (uuid === undefined) {
      navigate("/");
      return;
    }

    // This is broken, page refresh on its own and sdk has invalid token
    // if (!sdkRef.current) sdkRef.current = new IntelliProveService(setting.actionToken!, setting.authenticationMethod);
    // console.log(sdkRef.current.instance?.api.authentication)

    const sdk = new IntelliProveService(setting.actionToken!, setting.authenticationMethod);

    try {
      buckets_response = await sdk.instance!.getBuckets(uuid);
      setBuckets(buckets_response);
    } catch (e) {
      console.error(e);
    }
  };

  if (buckets === null) {
    console.info(buckets_response);
    if (!buckets_response) {
      __fetchBuckets();
    } else {
      setBuckets(buckets_response);
    }
  }

  useEffect(() => {
    __bucketsToTrendItems(buckets?.buckets ?? []);
  }, [buckets]);

  useEffect(() => {
    __bucketsToTrendItems(buckets?.buckets ?? []);
  }, []);

  return {
    data,
  };
};
