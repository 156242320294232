import { useSearchParams, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Provider, shallowEqual } from "react-redux";

import "@/translations/i18next";

import { useAppDispatch, useAppSelector } from "./hooks";
import React, { useCallback, useEffect, useState } from "react";
import { setMetadata, setSetting } from "./store/reducers/setting.reducer";
import { ModalProvider } from "./utils/modal.provider";
import { AppModal, iAppModal } from "./components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { IntelliProveService } from "./utils/intelliprove.service";
import { defaultTheme, useTheme } from "./context/theme";
import { Cookie, useLanguage } from "./context/language";
import { ScreenSizeTooSmallModal } from "./components/Modal/ModalScreenSize";
import { customerFromJwt, debugLog } from "./utils/helper";
import { AuthenticationMethod, PluginSettings, QuestionItem } from "intelliprove-streaming-sdk";
import { useCookies } from "react-cookie";
import { IconCircleX } from "./components/Icons/icon_circle_x";
import { IconCircleKey } from "./components/Icons/icon_circle_key";
import { monitor } from "./utils/monitoring.service";
import { parseInt } from "lodash";

export interface iAppContext {
  sdk: IntelliProveService | null;
}
export const AppContext = React.createContext<iAppContext>({
  sdk: null,
});
export const AppBootstrap = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage } = useLanguage();
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([Cookie.Language]);
  const [error, setError] = useState<iAppModal | undefined>(undefined);
  const [appState, setAppState] = useState<iAppContext>({
    sdk: null,
  });

  const __getSearchParam = useCallback(
    (key: string) => {
      if (searchParams.get(key)) {
        let val = searchParams.get(key);
        val?.replaceAll(" ", "+");
        return val;
      }

      // attempt to get via location API
      const urlSearchParams = new URLSearchParams(window.location.search);
      let val = urlSearchParams.get(key);
      if (val) {
        val = val.replace(" ", "+");
      }
      return val;
    },
    [window.location.search, location.search],
  );

  /**
   * Only accept object
   * https://app.clickup.com/t/86bxnx995 seems caused by (empty?) string on theming property
   * @param theming
   * @returns object | null
   */
  const __genThemeObject = (theming: any): object | null => {
    return typeof theming === "object" ? theming : null;
  };

  useEffect(() => {
    (async () => {
      if (!setting.actionToken) {
        const authToken = __getSearchParam("action_token") ?? __getSearchParam("auth0");

        if (!authToken) {
          theme.applyTheme({});

          if (location.pathname !== "/") {
            return navigate("/", { replace: true });
          }

          setError({
            isOpen: true,
            title: t("access_not_granted_title", "Access not granted"),
            text: t(
              "access_not_granted",
              "To use the health check, you must have the access key. If you don't have it, please get in touch with your responsible team to request access.",
            ),
            dismissable: false,
            icon: <IconCircleX />,
          });
          return;
        }

        monitor.setCustomer(customerFromJwt(authToken));
        monitor.trackView("/");

        try {
          let isDefaultTheme = false;

          const language = __getSearchParam("language") ?? cookies.language ?? "en";
          const patient = __getSearchParam("patient");
          const performer = __getSearchParam("performer");
          const mode = __getSearchParam("mode");
          const defaultTheming = __getSearchParam("default");
          const duration = __getSearchParam("duration");
          const authenticationMethod = !__getSearchParam("auth0") ? AuthenticationMethod.ActionToken : AuthenticationMethod.Auth0;
          const skipWelcome = __getSearchParam("no_welcome");
          const skipResults = __getSearchParam("no_results");
          const questionIdsJoined = __getSearchParam("q_ids");

          if (defaultTheming) {
            try {
              const value = JSON.parse(defaultTheming);
              if (value) {
                isDefaultTheme = true;
              }
            } catch (e) {
              monitor.trackError(e);
              debugLog(`defaultTheming was truthy but JSON.parse was failed. Value -> ${defaultTheming}`);
            }
          }

          debugLog(`AppBootstrap: removing all search params from url`);
          window.history.replaceState({}, document.title, window.location.pathname);

          debugLog(`AppBootstrap: got action_token, checking token validity`);

          const sdk = new IntelliProveService(authToken, authenticationMethod);
          const settings = await sdk.instance?.getPluginSettings();
          setAppState({ sdk: sdk });

          if (!settings) {
            console.warn(`getPluginSettings returning undefined?`);
            return;
          }

          const onboarding = !Boolean(localStorage.getItem("onboarding"));

          await changeLanguage(language, authToken, authenticationMethod);

          settings.skip_welcome = skipWelcome != null ? ["1", "true"].includes(skipWelcome) : settings.skip_welcome;
          settings.skip_results = skipResults != null ? ["1", "true"].includes(skipResults) : settings.skip_results;
          settings.embedded_iframe = mode != null ? mode === "iframe" : settings.embedded_iframe;
          settings.embedded_app = mode != null ? mode === "app" : settings.embedded_app;

          let questionDefs: QuestionItem[] = [];
          try {
            const questionIds = (questionIdsJoined ? questionIdsJoined.split(",") : []).map((id) => parseInt(id));
            if (sdk.instance && questionIds.length > 0) {
              questionDefs = await sdk.instance.getQuestions(questionIds);
            }
          } catch (err) {
            console.error(err);
          }

          const userId = monitor.metadata.userId;
          const sessionId = monitor.metadata.sessionId;
          dispatch(
            setSetting({
              language: language,
              onboarding: onboarding,
              actionToken: authToken,
              authenticationMethod,
              metadata: { patient, performer, userId, sessionId },
              duration: !duration || isNaN(Number(duration)) ? 20000 : Number(duration) * 1000,
              pluginSettings: new PluginSettings({
                ...settings,
                theming: __genThemeObject(settings.theming) ?? defaultTheme,
              }),
              questionnaireQuestions: questionDefs,
            }),
          );

          theme.applyTheme(isDefaultTheme ? {} : __genThemeObject(settings.theming) ?? {});
          if (settings.theming["dark_mode"] === true) {
            document.body.classList.add("dark");
          }

          if (location.pathname === "/") {
            if (settings.skip_welcome) {
              navigate(onboarding ? "/measurement/onboarding" : "/measurement/do", { replace: true, state: { from: location.pathname } });
            } else {
              navigate("/welcome", { replace: true, state: { from: location.pathname } });
            }
          }

          monitor.trackDeviceInfo();
        } catch (e) {
          monitor.trackError(e);
          debugLog(`ERROR:`, e);
          theme.applyTheme({});
          setError({
            isOpen: true,
            title: t("access_not_granted_title", "Access not granted"),
            text: t("access_not_granted_invalid_expired_key"),
            dismissable: false,
            icon: <IconCircleKey />,
          });
          return;
        }
      }
    })();
  }, [location.pathname, setting.actionToken]);

  useEffect(() => {
    const defaultTheming = __getSearchParam("default");
    if (!defaultTheming) return;

    try {
      const value = JSON.parse(defaultTheming);
      if (value) {
        theme.applyTheme({});
      }
    } catch (e) {
      monitor.trackError(e);
      debugLog(`fail: ${defaultTheming}`);
    }
  }, [window.location.search, location.search]);

  return (
    <AppContext.Provider value={appState}>
      <ModalProvider>
        {/* Router page content */}
        <Outlet />

        <ScreenSizeTooSmallModal />

        <AppModal
          isOpen={error?.isOpen}
          title={error?.title}
          text={error?.text}
          dismissText={t("try_again")}
          onDismiss={() => error?.onDismiss?.()}
          dismissable={error?.dismissable}
          icon={error?.icon}
        />
      </ModalProvider>
    </AppContext.Provider>
  );
};
