import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboarding7 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25 173L185.556 173" stroke={theme.value.functional_tertiary_500} strokeWidth="5" strokeLinecap="round" />
      <g opacity="0.16">
        <path
          d="M104.974 102.527C114.669 102.527 122.529 94.5035 122.529 84.6064C122.529 74.7093 114.669 66.6855 104.974 66.6855C95.279 66.6855 87.4189 74.7093 87.4189 84.6064C87.4189 94.5035 95.279 102.527 104.974 102.527ZM104.974 68.9257C113.458 68.9257 120.335 75.9456 120.335 84.6064C120.335 93.2672 113.458 100.287 104.974 100.287C96.49 100.287 89.6133 93.2672 89.6133 84.6064C89.6133 75.9456 96.49 68.9257 104.974 68.9257Z"
          fill={theme.value.support_500}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.7101 117.043L86.6842 117.06C86.2095 117.376 85.5682 117.248 85.2517 116.774C84.9353 116.3 85.0635 115.659 85.5382 115.343C87.4344 114.081 92.9431 110.454 105 110.454C117.057 110.454 122.566 114.081 124.462 115.343C124.937 115.659 125.065 116.3 124.748 116.774C124.432 117.248 123.791 117.376 123.316 117.06L123.29 117.043C121.617 115.929 116.494 112.517 105 112.517C93.506 112.517 88.3831 115.929 86.7101 117.043Z"
          fill={theme.value.support_500}
        />
      </g>
      <g opacity="0.16">
        <path
          d="M86.1117 125.632H83.0283C80.8192 125.632 79.0283 123.841 79.0283 121.632V118.558"
          stroke={theme.value.support_500}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M123.889 125.632H126.973C129.182 125.632 130.973 123.841 130.973 121.632V118.558"
          stroke={theme.value.support_500}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M86.1117 59.6104H83.0283C80.8192 59.6104 79.0283 61.4012 79.0283 63.6104V66.684"
          stroke={theme.value.support_500}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M123.889 59.6104H126.973C129.182 59.6104 130.973 61.4012 130.973 63.6104V66.684"
          stroke={theme.value.support_500}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <rect x="77.8467" y="135.063" width="54.3056" height="15.3263" rx="4" fill={theme.value.support_300} />
      <ellipse cx="105" cy="142.728" rx="4.72222" ry="4.71579" fill={theme.value.functional_tertiary_500} />
      <ellipse cx="104.999" cy="142.728" rx="5.90278" ry="5.89474" stroke="white" />
      <rect x="27.7227" y="52" width="154.556" height="106" rx="9" stroke={theme.value.support_500} strokeWidth="4" />
      <path
        d="M20 153.927H190V153.927C190 157.833 186.833 161 182.926 161H27.0737C23.167 161 20 157.833 20 153.927V153.927Z"
        fill={theme.value.support_500}
      />
      <rect x="93.1943" y="156.285" width="23.6111" height="2.35789" rx="1.17895" fill="white" />
    </svg>
  );
};
