import { JsonObject } from "../interfaces";

export default class PluginSettings {
  hide_check_privacy: boolean;
  hide_check_data_usage: boolean;
  skip_welcome: boolean;
  skip_results: boolean;
  theming: JsonObject;
  hide_watermark: boolean;
  embedded_iframe: boolean;
  embedded_app: boolean;
  url_terms_of_service: string | null;
  url_privacy_policy: string | null;
  url_website: string | null;
  url_product_guidelines: string | null;

  feature_flags: JsonObject | null;

  constructor(json: JsonObject) {
    this.hide_check_privacy = json.hide_check_privacy;
    this.hide_check_data_usage = json.hide_check_data_usage;
    this.skip_welcome = json.skip_welcome;
    this.skip_results = json.skip_results;
    this.theming = json.theming;
    this.hide_watermark = json.hide_watermark;
    this.embedded_iframe = json.embedded_iframe;
    this.embedded_app = json.embedded_app;
    this.url_terms_of_service = json.url_terms_of_service;
    this.url_privacy_policy = json.url_privacy_policy;
    this.url_website = json.url_website;
    this.url_product_guidelines = json.url_product_guidelines;
    this.feature_flags = json.feature_flags;
  }

  get embedded() {
    return this.embedded_iframe || this.embedded_app;
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
