import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { debugLog } from "./helper";
import { isMobile, deviceDetect } from "react-device-detect";
import { DatadogService } from "./datadog.service";
import StreamResolution from "intelliprove-streaming-sdk/dist/models/stream_resolution";

interface iMonitorMetaData {
  env: string;
  uuid: string;
  patient: string | null;
  performer: string | null;
  userId: string;
  sessionId: string;
  customer: string;
}

export class MonitoringService {
  private timeOfRecordingStart: number = Date.now();
  public metadata: iMonitorMetaData;

  private disabled: boolean = true;

  constructor() {
    this.metadata = {
      env: "",
      uuid: "",
      patient: null,
      performer: null,
      userId: "",
      sessionId: "",
      customer: "",
    };
  }

  _createUserId(): string {
    return btoa(Math.floor(Math.random() * 10 ** 9).toString());
  }

  _createSessionId(): string {
    return Date.now().toString(); // Generate current sessionId;
  }

  setup(env: string) {
    let userId = localStorage.getItem("intelliprove-usr");
    if (userId === null) {
      // Create userId and save to local storage
      userId = this._createUserId();
      localStorage.setItem("intelliprove-usr", userId);
    }

    this.metadata.env = env;
    this.metadata.sessionId = this._createSessionId();
    this.metadata.userId = userId;
    this.disabled = false;
    DatadogService.setUpLogging(env);
    DatadogService.setUpRum(env);
    DatadogService.setUser(this.metadata);
    debugLog("Started session");
  }

  setCustomer(customer: string) {
    this.metadata.customer = customer;
    DatadogService.setUser(this.metadata);
  }

  setMetadata(uuid: string, patient: string | null, performer: string | null) {
    this.metadata.uuid = uuid;
    this.metadata.patient = patient;
    this.metadata.performer = performer;
  }

  trackDeviceInfo() {
    let deviceInfo = deviceDetect(navigator.userAgent);

    this.triggerWebHook("DeviceInfo", {
      browserName: deviceInfo.browserName,
      browserVersion: deviceInfo.browserFullVersion,
      isMobile: isMobile,
      osName: deviceInfo.osName || deviceInfo.model,
      osVersion: deviceInfo.osVersion,
    });
  }

  trackView(viewName: string) {
    this.triggerWebHook("View", {
      path: viewName,
    });
  }

  trackClickEvent(eventName: string, additionalInfo: Object = {}) {
    this.triggerWebHook("ClickEvent", {
      eventName: eventName,
      ...additionalInfo,
    });
  }

  trackCameraInfo(
    requestedRes: StreamResolution | undefined,
    actualRes: StreamResolution | undefined,
    requestedFps: number | undefined,
    maxSupportedFps: number | undefined,
  ) {
    this.triggerWebHook("CameraInfo", {
      requestedWidth: requestedRes?.width,
      requestedHeight: requestedRes?.height,
      requestedFps: requestedFps,
      actualWidth: actualRes?.width,
      actualHeight: actualRes?.height,
      maxSupportedFps: maxSupportedFps,
    });
  }

  trackRecordingStarted(duration: number) {
    this.timeOfRecordingStart = Date.now();
    this.triggerWebHook("RecordingStarted", {
      duration: duration,
    });
  }

  trackRecordingStopped(cause: string) {
    this.triggerWebHook("RecordingStopped", {
      elapsedSinceStart: Date.now() - this.timeOfRecordingStart,
      cause: cause,
    });
  }

  trackQualityCheck(errorCode: number, isInitial?: boolean, isStop?: boolean) {
    this.triggerWebHook("QualityCheck", {
      elapsedSinceStart: Date.now() - this.timeOfRecordingStart,
      errorCode: errorCode,
      isInitial: isInitial,
      isStop: isStop,
    });
  }

  trackStreamingError(errorCode: number, errorType: string, isStop: boolean) {
    this.triggerWebHook("StreamingError", {
      elapsedSinceStart: Date.now() - this.timeOfRecordingStart,
      errorCode: errorCode,
      errorType: errorType,
      isStop: isStop,
    });
  }

  trackLiveResults(isFinal?: boolean) {
    this.triggerWebHook("LiveResults", {
      elapsedSinceStart: Date.now() - this.timeOfRecordingStart,
      isFinal: isFinal,
    });
  }

  trackFinalResults(hasHrvValues: boolean) {
    this.triggerWebHook("FinalResults", {
      hasHrvValues: hasHrvValues,
    });
  }

  trackErrorModal(reason?: string) {
    this.triggerWebHook("ErrorModalShown", {
      reason: reason,
    });
  }

  trackTryAgain() {
    this.triggerWebHook("ErrorModalAction", {
      errorModalAction: "try again",
    });
  }

  trackContinue() {
    this.triggerWebHook("ErrorModalAction", {
      errorModalAction: "continue",
    });
  }

  trackScreenTooSmall(screenWidth: number, screenHeight: number, desiredWidth: number, desiredHeight: number) {
    this.triggerWebHook("ScreenTooSmall", {
      screenWidth: screenWidth,
      screenHeight: screenHeight,
      desiredWidth: desiredWidth,
      desiredHeight: desiredHeight,
    });
  }

  trackError(error: unknown, ctx: Object | undefined = undefined) {
    datadogRum.addError(error, ctx);
  }

  private triggerWebHook(action: string, context: Object | undefined) {
    if (this.disabled) return;
    if (this.metadata.sessionId === "") return; // Only track log when session id is filled in

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      action: action,
      timestamp: Date.now(),
      ...this.metadata,
      context: context,
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      mode: "no-cors",
    };

    fetch(`${process.env.REACT_APP_API_URL}/logs/event`, requestOptions).catch((error) => debugLog(error));

    // Send to Datadog RUM too
    DatadogService.sendRumAction(action, {
      ...this.metadata,
      ...context,
    });
  }
}

export const monitor = new MonitoringService();
