import { iChartValue } from "@/components/Charts/Interfaces";
import { LineChart } from "@/components/Charts/LineChart";
import { Header } from "@/components/Header/Header";
import { BaseLayout } from "@/components/Layout/BaseLayout";
import { useTheme } from "@/context/theme";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTrends as useTrends } from "./Trends.hook";

import "./Trends.scss";
import { Button, ButtonType } from "@/components/Button/Button";
import { useAppSelector } from "@/hooks";
import { shallowEqual } from "react-redux";

export interface iTrendsPageItem {
  title: string;
  values: Array<iChartValue>;
}

export const TrendsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const state = location.state;
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);

  const navigate = useNavigate();
  const { uuid } = useParams();
  const { data } = useTrends(uuid, state?.buckets);

  return (
    <BaseLayout containerClass="trends-page" withPadding={false}>
      <div className="px-4 md:px-[25px]">
        <Header />
      </div>

      <div className="my-8 mx-auto text-center">
        <h3 className="uppercase text-xl font-bold text-support-500 mb-8">{t("measurement_history")}</h3>
        <div className="italic text-md trends-heading mb-6">
          {data.length > 0 ? <p>{t("measurement_history_description")}</p> : <p>{t("measurement_history_not_enough_data")}</p>}
        </div>
      </div>

      <div className="md:mx-auto w-full flex-grow">
        <div className="px-4 md:px-[25px] max-w-7xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:gap-8 ">
            {data.length > 0 &&
              data.map((item) => {
                const avg =
                  item.values.map((v) => (v.value ? v.value : 0)).reduce((acc, x) => acc + x, 0) /
                  item.values.filter((v) => v.value !== null).length;
                const grace = avg < 8 ? Math.ceil(avg / 3) : 7;

                return (
                  <LineChart
                    className=""
                    title={t(item.title)}
                    font={theme.value.font ?? ""}
                    colors={{
                      lineColor: (theme.value.dark_mode ? theme.value.brand_secondary : (theme.value.brand_primary as string)) + "80", // Add alpha of 50%
                      pointColor: theme.value.brand_tertiary as string,
                      pointBorderColor: theme.value.support_500 as string,
                      gridColor: (theme.value.support_500 as string) + "29", // Add alpha of 16%
                      textColor: theme.value.support_500 as string,
                      borderColor: theme.value.brand_tertiary as string,
                      ticksColor: (theme.value.support_500 as string) + "66", // Add Alpha of 40%
                    }}
                    values={item.values}
                    tooltips={true}
                    styling={{
                      grace: grace,
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className="flex flex-col items-center mx-4 sm:mx-0 flex-grow">
          <div className="my-8 flex flex-col sm:flex-row gap-4">
            <Button
              textStyles={{ textTransform: "uppercase" }}
              type={ButtonType.Accent}
              text={t("back_to_results", "Back to results")}
              onClick={() => navigate(-1)}
              className="h-[50px] px-[32px] flex-grow"
            />
          </div>
        </div>
      </div>

      <div className="flex text-support-500 items-end px-6 mb-4 pb-4 bt-8 text-center md:text-left">
        <span className="text-[8px]">{t("disclaimer_results")}</span>
      </div>
    </BaseLayout>
  );
};
