import {
  IconHeartRate,
  IconRespiratoryRate,
  IconMentalStressScore,
  IconAnsBalance,
  IconMentalHealthRisk,
  IconMorningReadiness,
  IconResonantBreathingScore,
} from "../Icons/Biomarker";

export const IconFromName = ({
  name,
  className = "w-full",
}: {
  name: string;
  className?: string;
}) => {
  // Fallback for non existance image
  let Icon = IconHeartRate;

  switch (name) {
    case "respiratory_rate":
      Icon = IconRespiratoryRate;
      break;
    case "acute_mental_stress_score":
      Icon = IconMentalStressScore;
      break;
    case "ans_balance":
      Icon = IconAnsBalance;
      break;
    case "mental_health_risk":
      Icon = IconMentalHealthRisk;
      break;
    case "morning_readiness":
      Icon = IconMorningReadiness;
      break;
    case "resonant_breathing_score":
      Icon = IconResonantBreathingScore;
      break;
  }

  return <Icon className={className} />;
};
