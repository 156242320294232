import WebsocketMessage from "../models/websocket_message";
import WebsocketSettings from "../models/websocket_settings";

export default class IntelliProveWebsocketService {
  settings: WebsocketSettings;

  _ws: WebSocket | null = null;
  _streamId: string | null = null;
  _callback: CallableFunction | null = null;

  constructor(settings: WebsocketSettings) {
    this.settings = settings;
    this.send = this.send.bind(this);
  }

  connect(streamId: string, callback: CallableFunction) {
    const url = `${this.settings.endpoint}?streamId=${streamId}`;
    this._callback = callback;
    this._streamId = streamId;
    this._ws = new WebSocket(url);

    return new Promise((resolve, reject) => {
      this._ws.addEventListener("error", () => {
        reject("Unable to connect to websocket!");
      });
      this._ws.addEventListener("open", resolve);
      this._ws.addEventListener("message", (event) => {
        if (event.data === "") {
          return;
        }
        this._callback(JSON.parse(event.data));
      });
    });
  }

  send(frame: string | null, final: boolean = false) {
    if (this._ws && this._ws.readyState === WebSocket.OPEN) {
      const message = new WebsocketMessage(frame, this._streamId, final);
      this._ws.send(JSON.stringify(message));
    }
  }

  close() {
    if (!this._ws) return;

    const message = new WebsocketMessage(null, this._streamId, true);
    this._ws.send(JSON.stringify(message));

    this._ws.close(1000, this._streamId);
  }
}
