import "./ButtonSwitchLanguage.scss";
import { useLanguage } from "@/context/language";
import Typography from "../Typography/Typography";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import cx from "classnames";

export enum iButtonSwitchLanguage {}

interface Option {
  value: string;
  label: string;
}

const languages: Option[] = [
  {
    value: "en",
    label: "ENG",
  },
  {
    value: "nl",
    label: "NL",
  },
  {
    value: "fr",
    label: "FR",
  },
];

function useClickOutside(ref: MutableRefObject<HTMLDivElement | null>, onClickOutside: () => void) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export const ButtonSwitchLanguage = () => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [dropdown, setDropdown] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const selected = languages.find((f) => f.value === language);

  const handleClickDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleChangeLanguage = (key: Option) => {
    changeLanguage(key.value);
    setDropdown(false);
  };

  useClickOutside(dropdownRef, () => {
    setDropdown(false);
  });

  return (
    <div
      className={cx(
        "language-picker cursor-pointer overflow-hidden right-[0px] border border-support-300 dark:border-2",
        dropdown ? "rounded-[20px]" : "rounded-[40px]",
      )}
      ref={dropdownRef}
    >
      <div className="flex flex-col text-center divide-y bg-brand-tertiary">
        <div className="flex flex-row items-center gap-2 p-[10.25px] sm:p-[11.25px]" onClick={handleClickDropdown}>
          <span className={`caret-dropdown ${dropdown ? "active" : ""}`} />
          <Typography.Title size={6} className="text-support-500">
            {selected?.label}
          </Typography.Title>
        </div>
        {dropdown && (
          <div className="language-dropdown">
            <ul className="divide-y">
              {languages
                .filter((f) => f.value !== selected?.value)
                .map((key, index) => (
                  <li
                    className={cx(
                      "px-3 py-2 hover:bg-support-300 dark:text-support-300 hover:text-brand-primary",
                      selected?.value === key.value && "bg-accent",
                    )}
                    key={index}
                    onClick={() => handleChangeLanguage(key)}
                  >
                    <Typography.Title size={6} className="transition-colors">
                      {key.label}
                    </Typography.Title>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
