import store from "@/store/store";
import { defaultLang } from "@/translations/i18next";
import { debugLog } from "@/utils/helper";
import { IntelliProveService } from "@/utils/intelliprove.service";
import { AuthenticationMethod } from "intelliprove-streaming-sdk";
import { FC, useState, useContext, useCallback, createContext } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export enum Cookie {
  Language = "language",
}

const useValue = () => {
  const {i18n} = useTranslation()
  const [cookies, setCookie] = useCookies([Cookie.Language]);
  const [language, setLanguage] = useState<string>(
    cookies[Cookie.Language] || defaultLang
  );

  const syncTranslations = useCallback(async (lang: string, actionToken?: string, authenticationMethod?: AuthenticationMethod) => {
    try {
      const setting = store.getState().setting;
      const authToken = actionToken ?? setting.actionToken;

      if (!authToken) {
        debugLog(`useLanguage: sync translations prevented due to no action_token!`)
      }

      const sdk = new IntelliProveService(authToken!, authenticationMethod ?? setting.authenticationMethod);

      debugLog(`useLanguage: sync translations -> ${lang}`)
      const translations = await sdk.instance?.getPluginTranslations(
        lang
      );

      if (!translations || !translations.keys) {
        throw new Error(`Translations empty!`)
      }

      if (translations) {
        debugLog(`useLanguage: override translation from sdk`, lang, Object.keys(translations.keys).length)
        i18n.addResourceBundle(lang, "translation", translations.keys);
      }
    } catch (e) {
      debugLog(`useLanguage: failed to get translation from SDK, using default translations`, e);
    }
  }, [])

  const changeLanguage = useCallback(
    async (lang: string, actionToken?: string, authenticationMethod?: AuthenticationMethod) => {
      await syncTranslations(lang, actionToken, authenticationMethod)
      setCookie(Cookie.Language, lang);
      setLanguage(lang);
    },
    [setCookie]
  );

  return {
    language,
    changeLanguage,
    syncTranslations,
  };
};

const LanguageContext = createContext({} as ReturnType<typeof useValue>);
const useLanguage = () => useContext(LanguageContext);
const LanguageProvider: FC<any> = (props) => {
  return <LanguageContext.Provider value={useValue()} {...props} />;
};

export { LanguageProvider, useLanguage };
